<template>
  <!-- 求购管理 -->
  <div class="page-card-demo">
    <!-- 搜索筛选 -->
    <FormSearch
      :getdata="getdata"
      :form-inline="formInline"
      reset
      :form-item-arr="searchFormItemArr"
    />
    <!-- 表格  分页 -->
    <div class="page-container-table">
      <Table
        :item-data="itemData"
        :list-data="listData"
        :operation-button="operationButton"
        :loading="loading"
      >
        <template #status="{ row }">
          <span>{{ row.status === 0 ? '进行中' : '已下架' }}</span>
          <span class="reason">{{ row.reason === 1 ? '(售出下架)' : row.reason === 2 ? '(超时下架)' : row.reason === 3 ? '(主动下架)': '' }}</span>
        </template>
      </Table>
      <!-- 分页 -->
      <Pagination :params="formInline" :total="total" :in-article="listData.length" :get-data-list="getdata" />
    </div>
  </div>
</template>

<script>
import FormSearch from '@/components/FormSearch.vue'
import Table from '@/components/Table.vue'
import Pagination from '@/components/Pagination2.vue'
import { TradeWantToBuyPage } from '@/api/shoppingMall'
export default {
  components: { FormSearch, Table, Pagination },
  data() {
    return {
      formInline: {
        pageSize: 10,
        pageNum: 1
      },
      listData: [],
      total: 0,
      loading: false,
      searchFormItemArr: [
        { type: 'input', label: '询价单号', value: 'inquiryNo', width: '80px' },
        { type: 'select', label: '状态', value: 'status', pLabel: 'dictName', pValue: 'dictId', child: [{ dictId: 0, dictName: '进行中' }, { dictId: 1, dictName: '已下架' }], width: '80px' },
        { type: 'input', label: '求购企业', value: 'cmpName', width: '80px' }
      ],
      itemData: [
        { label: '询价单号', prop: 'inquiryNo', width: 120 },
        { label: '求购企业', prop: 'cmpName', width: 120 },
        { label: '求购商品', prop: 'goods', width: 280 },
        { label: '发布时间', prop: 'createTime', width: 180 },
        { label: '状态', prop: 'status', child: [{ dictName: '进行中', dictId: 0 }, { dictName: '已下架', dictId: 1 }], width: 180 }
      ],
      operationButton: [
        { bType: 'primary', label: '查看详情', handleEvent: this.viewDetails }
      ],
      dialogVisible: false,
      currentRow: {
        id: '',
        reason: 0
      }
    }
  },
  methods: {
    // // 获取分页数据
    getdata(type) {
      if (type === 'reset') {
        this.formInline = {
          pageSize: 10,
          pageNum: 1
        }
      } else if (!type) {
        this.formInline.pageNum = 1
      }
      TradeWantToBuyPage(this.formInline, res => {
        this.listData = [...res.data.records]
        this.total = res.data.total
      })
    },
    // 下架
    offTheShelf({ id }) {
      this.dialogVisible = true
      this.currentRow.id = id
    },
    // 选择下架原因
    switchUndercarriage(val) {
      this.currentRow.reason = val
    },
    // 查看详情
    viewDetails({ id }) {
      this.$router.push({
        path: '/shoppingMall/purchaseDetails',
        query: { id }
      })
    }
  }
}
</script>

<style scoped lang="scss">
:deep .el-tabs__nav-wrap::after {
  width: 0;
}
:deep .el-tabs__active-bar {
  display: none;
}
.reason {
  color: rgb(124, 124, 122);
}
</style>
